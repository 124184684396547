exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-algemene-voorwaarden-js": () => import("./../../../src/pages/algemene-voorwaarden.js" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-hoe-werkt-het-js": () => import("./../../../src/pages/hoe-werkt-het.js" /* webpackChunkName: "component---src-pages-hoe-werkt-het-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kindercoaching-js": () => import("./../../../src/pages/kindercoaching.js" /* webpackChunkName: "component---src-pages-kindercoaching-js" */),
  "component---src-pages-over-mij-js": () => import("./../../../src/pages/over-mij.js" /* webpackChunkName: "component---src-pages-over-mij-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-tarieven-js": () => import("./../../../src/pages/tarieven.js" /* webpackChunkName: "component---src-pages-tarieven-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-trainingen-js": () => import("./../../../src/pages/trainingen.js" /* webpackChunkName: "component---src-pages-trainingen-js" */)
}

